import * as React from 'react';

import { Container, Grid, GridItem, Icon } from '@ao-internal/components-react';

import CookieConsent from 'react-cookie-consent';
import { Link } from 'gatsby';

interface HeaderProps {
	siteTitle: string;
	specialEvent?: boolean;
}

const Header: React.SFC<HeaderProps> = (props) => (
	<React.Fragment>
		{props.specialEvent && (
			<CookieConsent
				location="none"
				containerClasses="relative px-8 py-4 bg-white text-primary text-sm border-b-1 border-gray-10"
				buttonText="Accept Cookies"
				buttonClasses="cta"
				enableDeclineButton
				declineButtonText="Reject Cookies"
				declineButtonClasses="cta cta-secondary px-2"
				cookieName="aoTech_allow-cookies"
				cookieValue="true"
				expires={150}>
				Our site uses cookies to give you the best experience.
			</CookieConsent>
		)}

		<header className="header border-gray-20 px-4 py-2">
			<Container className="items-center">
				<Grid>
					<GridItem key="1" span={{ def: 9, md: 6}} className="flex items-center">
						<Link
							to="/"
							className="header__icon-logo border-gray-20 pr-2 mr-2 sm:pr-4 md:mr-4">
							<Icon size="3x" icon="ao-logo" label="AO" />
						</Link>
						<div className="header__title text-display text-display-sm sm:text-display mb-0">
							<Link to="/">{props.siteTitle}</Link>
						</div>
					</GridItem>
					<GridItem
						key="2"
						span={{ def: 3, md: 6}}
						className="flex justify-end items-center text-body">
						<Link to="/archive/" className="text-xs sm:text-base">
							Blog
						</Link>
						<Link to="/events/" className="text-xs sm:text-base ml-2 sm:ml-6">
							Events
						</Link>
					</GridItem>
				</Grid>
			</Container>
		</header>
	</React.Fragment>
);

export default Header;
