import '../scss/structure.scss';

import { StaticQuery, graphql } from 'gatsby';

import Footer from './Footer';
import Header from './Header';
import Helmet from 'react-helmet';
import React from 'react';

export interface MetaData {
	title: string;
	description: string;
}

export interface LayoutProps {
	data: {
		wp: {
			generalSettings: {
				title: string;
				description: string;
			};
		};
	};
	children?: {
		props?: {
			data?: {
				event?: {
					eventFieldGroup?: {
						specialEvent: boolean;
					};
				};
			};
		};
	};
}

export const InnerLayout: React.SFC<LayoutProps> = (props) => {
	let isSpecialEvent = false;

	if (
		props &&
		props.children &&
		props.children.props &&
		props.children.props.data &&
		props.children.props.data.event &&
		props.children.props.data.event.eventFieldGroup
	) {
		isSpecialEvent =
			props.children.props.data.event.eventFieldGroup.specialEvent;
	}

	return (
		<React.Fragment>
			<Helmet>
				<title>{props.data.wp.generalSettings.title}</title>
				<meta
					name="description"
					content={props.data.wp.generalSettings.description}
				/>
				<html lang="en" />
			</Helmet>
			<div className="vi-update">
				<div className="overflow-hidden">
					<Header
						siteTitle={props.data.wp.generalSettings.title}
						specialEvent={isSpecialEvent}
					/>
					<div className="p-4 text-body">{props.children}</div>
					<Footer />
				</div>
			</div>
		</React.Fragment>
	);
};

export const Layout: React.SFC<{}> = (props) => (
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				wp {
					generalSettings {
						title
						description
					}
				}
			}
		`}
		// @ts-ignore
		render={(data) => <InnerLayout data={data} {...props} />}
	/>
);

export default Layout;
